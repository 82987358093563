<template>
  <div class="content min_box" v-wechat-title="details.mass_name?'众创-'+ details.mass_name:'详情'">
    <div class="scroll_center">
      <!-- 头部 -->
      <div class="set_margin" id="头部标题-1">
        <div class="ff" id="span1">
          <top :info="{ name: details.mass_name }" />
        </div>
      </div>
      <div
        class="st"
        id="轮播图介绍-2"
        v-if="video_img_vr.invest_slide_img && video_img_vr.invest_slide_img.length > 0"
      >
        <!-- 轮播图 -->
        <div class="ff" id="span2">
          <swipers :list="video_img_vr.invest_slide_img" />
        </div>
      </div>
      <!-- 简介 -->
      <div class="set_margin" id="简介-3" v-if="details.mass_intro">
        <div class="ff" id="span3">
          <detailType :detail="details.mass_intro" :title="'简介'" />
        </div>
      </div>
      <!-- 基本资料 -->
      <div class="set_margin" id="基本资料-4">
        <div class="b_top ff" id="span4">
          <div class="b_top_title">
            <span class="span titles">基本资料</span>
          </div>
          <div class="basicList jianjie">
            <!-- <information type="7" :basicList="basicList" /> -->
            <div class="basicItem">
              <span class="left">更新时间</span>
              <span class="span">{{
                details.mass_update_time ? details.mass_update_time : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">建筑面积</span>
              <span class="span">{{
                details.mass_build_area ? details.mass_build_area : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">装修程度</span>
              <span class="span">{{
                details.mass_deco_degree ? details.mass_deco_degree : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">层数</span>
              <span class="span">{{
                details.mass_num_layers ? details.mass_num_layers : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">楼层高度</span>
              <span class="span">{{
                details.mass_floor_heigh ? details.mass_floor_heigh : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">租金</span>
              <span class="span">{{
                details.mass_rent ? details.mass_rent : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">物业公司</span>
              <span class="span">{{
                details.mass_pro_company ? details.mass_pro_company : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">物业费</span>
              <span class="span">{{
                details.mass_property_fee ? details.mass_property_fee : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">车位数</span>
              <span class="span">{{
                details.mass_num_vehicles ? details.mass_num_vehicles : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">车位月租金</span>
              <span class="span">{{
                details.mass_veh_rent_parking
                  ? details.mass_veh_rent_parking
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">空调配置</span>
              <span class="span">{{
                details.mass_air_conditioning
                  ? details.mass_air_conditioning
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">空调开放时长</span>
              <span class="span">{{
                details.mass_air_condi_open_time
                  ? details.mass_air_condi_open_time
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">最短租期</span>
              <span class="span">{{
                details.mass_min_lease_term
                  ? details.mass_min_lease_term
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">电梯</span>
              <span class="span">{{
                details.mass_elevator ? details.mass_elevator : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">网络</span>
              <span class="span">{{
                details.mass_network ? details.mass_network : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">入驻企业</span>
              <span class="span">{{
                details.mass_check_enterprise
                  ? details.mass_check_enterprise
                  : "暂无"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 规划图 -->
      <div class="set_margin" id="规划图-5" v-if="details.mass_plan_img">
        <div class="ff" id="span5">
          <planeGuide :simages="[details.mass_plan_img]" />
        </div>
      </div>
      <!-- 视频/音频 -->
      <div
        class="set_margin"
        id="视频-6"
        v-if="video_img_vr.invest_video && video_img_vr.invest_video.length > 0"
      >
        <div class="ff" id="span6">
          <videoaudio :audios="video_img_vr.invest_video" />
        </div>
      </div>
      <!-- VR看景 -->
      <div
        class="set_margin"
        id="VR看景-7"
        v-if="video_img_vr.vrlist && video_img_vr.vrlist.length > 0"
      >
        <div class="ff" id="span7">
          <detailType :title="'VR看景'" :list="video_img_vr.vrlist" />
        </div>
      </div>
      <!-- 导航 -->
      <div
        class="set_margin"
        id="导航-8"
        v-if="details.Latitude && details.Longitude"
      >
        <div class="ff" id="span8">
          <maps :title="'导航'" :info="mapDetail" />
        </div>
      </div>
      <!-- 特色板块 -->
      <!-- 户型介绍(楼宇/众创板块) -->
      <div class="set_margin" id="户型介绍-13" v-if="house_types && house_types.length > 0">
        <div class="ff" id="text13">
          <hotel :list="house_types" />
        </div>
      </div>
      <!-- 投资详情页必有板块 -->
      <!-- 特色优势 -->
      <div
        class="set_margin"
        id="孵化服务-20"
        v-if="details.mass_incubat_service"
      >
        <div class="ff" id="text20">
          <detail :name="'孵化服务'" :detail="details.mass_incubat_service" />
        </div>
      </div>
      <div
        class="set_margin"
        id="特色优势-21"
        v-if="details.mass_character_advant"
      >
        <div class="ff" id="text21">
          <detail :name="'特色优势'" :detail="details.mass_character_advant" />
        </div>
      </div>
      <!-- 周边配套 -->
      <div class="set_margin" id="周边配套-22" v-if="details.mass_peri_support">
        <div class="ff" id="text22">
          <detail :name="'周边配套'" :detail="details.mass_peri_support" />
        </div>
      </div>
    </div>
    <!-- <scooltop
      @start="start"
      @tosloo="tosloo"
      @totop="totop"
      :listInfo="scorllList"
    /> -->
    <!-- 底部导航栏 -->
    <div class="footer">
      <goodsNav :navBoj="navBoj" v-if="navBoj.phone && navBoj.name" />
    </div>
  </div>
</template>

<script>
import { gardendetail, video_img_vrs } from "@/api/http";
import top from "@/components/investmentCompoent/top.vue";

import planeGuide from "@/components/investmentCompoent/planeGuide.vue";
// import scooltop from '@/components/investmentCompoent/scooltop.vue'
import videoaudio from "@/components/investmentCompoent/videoaudio.vue";
import detail from "@/components/investmentCompoent/detail.vue";
import detailType from "@/components/investmentCompoent/detailType.vue";
import maps from "@/components/investmentCompoent/map.vue";
import swipers from "@/components/investmentCompoent/swipers.vue";
import hotel from '@/components/investmentCompoent/hotel.vue'
import goodsNav from "@/components/investmentCompoent/goodsNav.vue";
export default {
  data() {
    return {
      //高度
      scrollheight: 0,
      //锚点跳转id
      intoindex: "",
      //所有板块名称
      scorllList: [],
      details: {},
      video_img_vr: {},
      mapDetail: {},
	  house_types: [],
      navBoj: {},
      //所有头部表示1,2,3
      arr: [],
      //基本资料
      info: null,
      //类型
      type: "",
      title: "投资详情",
      homeFlag: false,
      params: {},
    };
  },
  components: {
    top,
    planeGuide,
    // scooltop,
    videoaudio,
    detail,
    detailType,
    hotel,
    maps,
    swipers,
    goodsNav,
  },
  methods: {
    //点击目录进行锚点跳转
    tosloo(i) {
      //x轴锚点跳转
      this.$nextTick(() => {
        this.intoindex = "span" + this.arr[i];
      });
    },
    //点击目录回到顶部
    totop() {
      this.intoindex = "span1";
    },
  },
  created() {
    this.params = this.$route.params;
    //轮播图/全景VR/音视频
    video_img_vrs({
      dic_type: 2,
      vr_type: 1,
      id: this.params.id,
    }).then((res) => {
      this.video_img_vr = res.data;
    });
    gardendetail(
      {
        region_code: this.params.code,
        id: this.params.id,
      },
      "massddetail"
    ).then((res) => {
      this.details = res.data.mass_detail;
      this.house_types = res.data.house_types;
      //地图
      this.mapDetail = {
        lat: this.details.Latitude,
        lng: this.details.Longitude,
        name: this.details.mass_name,
      };
      this.navBoj = {
        name: this.details.liaison_man,
        phone: this.details.mass_phone,
      };
    });
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #eeeeee;
  .scroll_center {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    .set_margin {
      overflow: hidden;
      margin: 6px 0;
      padding: 10px;
      background-color: #fff;
      border-radius: 16px;
      font-size: 1rem;
      box-shadow: 0 0 6px rgba(124, 124, 124, 0.2);
    }
  }
}
</style>
